import React from "react";

export default function Main() {
  return (
    <>
      <main className="main" role={`main`}>
        <div className="main__text">
          <p className="main__para one">
            I design digital products and i’m currently based in Berlin,
            Germany.
          </p>
          <p className="main__para two">
            i’m curious about people, places and everything in between.
          </p>
          <p className="main__para three">
            at present, I lead design system efforts at{" "}
            <a href="https://urbansportsclub.com/" target="blank">
              Urban Sports Club
            </a>{" "}
            as a senior product designer. in the past, I have worked with teams at{" "}
            <a href="https://sparkar.facebook.com/ar-studio/" target="blank">
              Meta
            </a>
            ,{" "}
            <a href="https://global.goodpatch.com/" target="blank">
              GoodPatch
            </a>
            ,{" "}
            <a href="https://quidax.com/" target="blank">
              Quidax
            </a>
            ,{" "}
            <a href="https://moduscreate.com/" target="blank">
              Modus Create
            </a>
            ,{" "}
            <a href="https://fourthcanvas.co/" target="blank">
              FourthCanvas
            </a>
            .
          </p>
          <p className="main__para four">
            when i’m not designing, I like to take  
            {" "}
              <a href="https://vs.co/dwfvz9gr" target="blank">
              pictures
            </a>  {" "}
            of people and places I travel to. other times, I watch my favourite team—
            <a href="https://www.arsenal.com/" target="blank">
              Arsenal
            </a>{" "}
            or rewatch an episode of{" "}
            <a href="https://www.netflix.com/ng/title/70143830" target="blank">
              The Big Bang Theory
            </a>{" "}
            or{" "}
            <a href="https://www.netflix.com/ng/title/70136120" target="blank">
              The Office
            </a>
            .
          </p>
        </div>
      </main>
    </>
  );
}
